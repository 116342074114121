import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import {
    SpaceBetween,
    Text,
    TooltipByClick,
    TooltipTitleContainer,
    VerticalLine
} from '../../../../../components';

export type ObjectiveDescriptionsContainerProps = {
    setIsTooltipOpenIndex: (status: null | number) => void;
    tooltipOpenIndex: number | null;
    isActive: boolean;
    descriptionsList: string[];
    scoreMarks: string[];
    isDescopeModalOpen: boolean;
};

const ObjectiveDescriptionsContainer: React.FC<ObjectiveDescriptionsContainerProps> = ({
    setIsTooltipOpenIndex,
    tooltipOpenIndex,
    isActive,
    descriptionsList,
    scoreMarks,
    isDescopeModalOpen
}) => {
    return (
        <MarksListDescriptionsContainer>
            {descriptionsList.map((item: string, index: number) => {
                const itemAry = (item.split(' '));
                return (
                    <MarksListDescriptionWrap key={`marks-list-desc-wrapper-${index}`}>
                        <SliderDescriptionBlock>
                            <MarksListDescription>{item}</MarksListDescription>
                            {  !(itemAry.length < 32) ?
                            <TooltipByClick
                                index={index}
                                key={`tooltip-by-click-${index}`}
                                width={'400px'}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                handleClose={() => {
                                    setIsTooltipOpenIndex(null);
                                }}
                                title={
                                    <TooltipTitleContainer
                                        key={`tooltip-by-click-title-${index}`}
                                        headerTitle={
                                            scoreMarks[index].toUpperCase() ||
                                            ''
                                        }
                                        handleClose={() => {
                                            setIsTooltipOpenIndex(null);
                                        }}
                                        content={
                                            <>
                                                <p
                                                    style={{
                                                        marginTop: '0',
                                                        marginBottom: '0px',
                                                        fontSize: '9.5pt',
                                                        whiteSpace: 'pre-line'
                                                    }}>
                                                    {item}
                                                </p>
                                            </>
                                        }
                                    />}
                                isOpen={tooltipOpenIndex === index}>
                                <DescriptionButton
                                    disabled={isActive || isDescopeModalOpen}
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        setIsTooltipOpenIndex(index);
                                    }}>
                                    Read more
                                </DescriptionButton>
                            </TooltipByClick>
                            : null}
                        </SliderDescriptionBlock>

                        <StyledVerticalLine />
                    </MarksListDescriptionWrap>
                );
            })}
        </MarksListDescriptionsContainer>
    );
};

const MarksListDescriptionsContainer = styled(SpaceBetween)`
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 5px;
    width: 100%;
    align-items: stretch;
`;

const MarksListDescriptionWrap = styled('div')`
    flex: 1 1 0px;
    min-width: 25px;
`;

const MarksListDescription = styled(Text)`
    font-size: 13px;
    color: #575757;
    margin: 5px 0;
    line-height: 16px;
    max-height: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;
`;

const SliderDescriptionBlock = styled(SpaceBetween)`
    padding: 0 15px;
    border-right: 2px solid #dedede;
    flex-direction: column;
    height: 100%;
`;

const DescriptionButton = styled(Button)`
    padding: '2px';
    width: '86%';
    min-width: '80px';
    margin-top: '5px';
    font-size: '11px';
    transition: 'all .2s';
    opacity: 1;
    z-index: 50;
    text-transform: none !important;
    &:hover, &:focus-visible {
        opacity: 1;
    }
`;

const StyledVerticalLine = styled(VerticalLine)`
    width: 4px;
    background: #ccc;
    height: calc(100% - 30px);
    margin: 0 -6px 0 0;
`;

export default React.memo(
    ObjectiveDescriptionsContainer,
    (
        prevProps: ObjectiveDescriptionsContainerProps,
        nextProps: ObjectiveDescriptionsContainerProps
    ) => {
        if (
            prevProps.descriptionsList === nextProps.descriptionsList &&
            prevProps.setIsTooltipOpenIndex ===
                nextProps.setIsTooltipOpenIndex &&
            prevProps.isActive === nextProps.isActive &&
            prevProps.isDescopeModalOpen === nextProps.isDescopeModalOpen &&
            prevProps.tooltipOpenIndex === nextProps.tooltipOpenIndex
        ) {
            return true;
        }
        return false;
    }
);
